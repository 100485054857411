const en = {
    translation: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
        CONTINUE: 'Continue',
        NOT_SELECTED: 'Not selected',
        SELECTED_FILE: 'Selected file',
        UPLOAD: 'Upload',
        SHORT_DATE_FORMAT: 'DD-MM-YYYY',
        MEDIUM_DATE_FORMAT: 'MMM DD, YYYY',
        UNKNOWN_ERROR: 'Unknown error',
        PAGE_NOT_FOUND: 'Page not found',
        GO_TO_FRONT_PAGE: 'Go to front page',
        MISSING_ACCESS: 'Missing access',
        MISSING_ACCESS_TEXT: 'You do not have access to this page',
        I_ACCEPT: 'I accept',
        TERMS_AND_CONDITIONS: 'the terms and conditions',
        TOTAL: 'Total',
        OF_THIS: 'of this',
        TAX: 'tax',
        GO_TO_PAYMENT: 'Go to payment',

        // REPORTS
        REPORTS_TYPE: 'Type',
        REPORTS_NAME: 'Name',
        REPORTS_DATE: 'Date',
        REPORTS_DOWNLOAD: 'Download',
        REPORTS_TYPE_GENERAL_ASSEMBLY: 'General Assembly',
        REPORTS_TYPE_BOARD_MEETING: 'Board Meeting',
        REPORTS_SHOW: 'Show',

        // COUNCIL
        COUNCIL_ROOM: 'Room',
        COUNCIL_EMAIL_CHANGED_SUCCESS: 'The council email has been changed',
        COUNCIL_ROOM_CHANGED_SUCCESS: 'The council room has been changed',
        COUNCIL_CONTENT_CHANGED_SUCCESS: 'The council content has been changed',
        COUNCIL_ADMIN_REMOVED_SUCCESS: 'Admin has been removed from the council',
        COUNCIL_ADMIN_ADDED_SUCCESS: 'Admin has been added to the council',
        COUNCIL_INFORMATION: 'Information',
        COUNCIL_INFORMATION_IS_VISIBLE_TO_STUDENTS:
            'The above information is visible to all users with the following educations:',
        COUNCIL_ADMINS: 'Admins',
        COUNCIL_ADD_ADMIN: 'Add admin',
        COUNCIL_REMOVE_ADMIN: 'Remove admin',
        COUNCIL_ADMIN_NAME: 'Name',
        COUNCIL_ADMIN_EMAIL: 'Email',
        COUNCIL_ADMIN_ACTIONS: 'Actions',
        COUNCIL_ENTER_EMAIL_FOR_NEW_ADMIN: 'Enter the email of the person you want to add as an admin for',
        COUNCIL_THIS_ACTION_IS_IRREVERSIBLE: 'This action is irreversible and will',
        COUNCIL_REMOVE_YOU_AS_ADMIN: 'remove you as admin for the council',
        COUNCIL_ARE_YOU_SURE_YOU_WANT_TO_REMOVE: 'Are you sure you want to remove',
        COUNCIL_AS_AN_ADMIN: 'as an admin for the council',
        COUNCIL_ENTER_YOUR_EMAIL_TO_CONFIRM: 'Enter your email to confirm',
        COUNCIL_CONTENT: 'Content',
        COUNCIL_RESET_CONTENT: 'Reset',
        COUNCIL_RESET_SAVE: 'Save',

        // EMAIL
        WRONG_TOKEN_TITLE: 'Wrong token',
        WRONG_TOKEN_ERROR: 'The token is invalid or has expired',
        EMAIL_CONFIRMED_TITLE: 'Email confirmed',
        EMAIL_CONFIRMED_MESSAGE: 'Your email has been confirmed',
        EMAIL_MAIL_UNSUBSCRIBED_TITLE: 'Unsubscribed',
        EMAIL_MAIL_UNSUBSCRIBED: 'You are now unsubscribed from the newsletter',
        EMAIL_CONFIRM_UNSUBSCRIBE_TITLE: 'Are you sure?',
        EMAIL_CONFIRM_UNSUBSCRIBE:
            'You are about to unsubscribe from the newsletter. Are you sure you want to continue?',
        EMAIL_UNSUBSCRIBE: 'Unsubscribe newsletter',

        // FAQ
        FAQ_TITLE: 'We are here to help',
        FAQ_SUBTITLE: 'Frequently asked questions',
        FAQ_SEARCH_PLACEHOLDER: 'Search for question or answer...',
        FAQ_NO_QUESTIONS: 'There are no faqs',

        // Events
        EVENTS_ALL: 'All',
        EVENTS_MONTH: 'Month',

        // Homepage
        EVENTS: 'Events',
        READ_MORE: 'Read more',
        SIGN_UP: 'Sign up',
        NO_DESCRIPTION: 'No description',

        // Navbar
        SEARCH: 'Search',
        SEARCH_PLACEHOLDER: 'Search for something...',

        // Search page
        SEARCH_RESULTS_FOR: 'Search results for',
        SEARCH_NO_RESULTS: 'No results found',
        SHOW_PDF_BUTTON: 'Show PDF',
        PAGE_SHOW_MORE_BUTTON: 'Show more',
        EVENT_SHOW_MORE_BUTTON: 'Show more',

        // Profile menu
        ADMIN_FOR_COUNCIL: 'Admin for',
        YOUR_PROFILE: 'Your profile',
        YOUR_COUPONS: 'Your coupons',
        SCAN_MEMBERS: 'Scan members',
        LOGOUT: 'Logout',

        // Reset Password Page
        RESET_PASSWORD: 'Reset password',
        RESET_PASSWORD_TEXT: 'Enter your new password.',
        RESET_LINK_INVALID: 'The reset link is invalid.',
        CREATE_PASSWORD: 'Create password',
        CREATE_PASSWORD_TITLE: 'Create your password',

        // Forgot Password Page
        FORGOT_PASSWORD: 'Forgot password',
        FORGOT_PASSWORD_TEXT: 'Enter your email to reset your password.',
        SEND_RESET_LINK: 'Send reset link',
        RESET_LINK_EMAIL_SENT_TO: 'Reset link is sent to',
        CLICK_THE_LINK_TO_RESET: '. Click the link to reset your password.',

        // Register Page
        REGISTER: 'Register',
        REGISTER_TO_YOUR_ACCOUNT: 'Register to your account',
        YOUR_FIRSTNAME: 'Your firstname',
        YOUR_LASTNAME: 'Your lastname',
        USERNAME: 'Username',
        YOUR_USERNAME: 'Your username',
        YOUR_EDUCATION: 'Your education',
        THERE_IS_SENT_AN_EMAIL_TO: 'There is sent an email to',
        PASSWORDS_DO_NOT_MATCH: 'The passwords do not match',
        CREATE_ACCOUNT: 'Create account',
        ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
        LOGIN_INSTEAD: 'Login instead',
        EMAIL_VALIDATION_ERROR: 'Your email needs to end with',

        // Login Page
        LOGIN_FORGOT_PASSWORD: 'Forgot password?',
        LOGIN: 'Login',
        LOGIN_TO_YOUR_ACCOUNT: 'Login to your account',
        YOUR_EMAIL_ARE_NOW_CONFIRMED: 'Your email are now confirmed',
        CONFIRMATION_EMAIL_SENT_TO: 'Confirmation email sent to',
        CLICK_THE_LINK_TO_CONFIRM: 'Click the link to confirm',
        EMAIL: 'Email',
        YOUR_EMAIL: 'Your email',
        YOUR_PASSWORD: 'Your password',
        RESEND_CONFIRMATION: 'Resend confirmation',
        DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
        CREATE_AN_ACCOUNT: 'Create an account',
        WOULD_YOU_TRY_TO_LOGIN_AGAIN: 'Would you try to login again?',
        AUTH_ERROR_INVALID_PASSWORD: 'Invalid email or password',
        AUTH_ERROR_NOT_CONFIRMED: 'Your account email is not confirmed',

        // Profile Page
        FIRSTNAME: 'Firstname',
        LASTNAME: 'Lastname',
        CITY: 'City',
        ZIP: 'Zip code',
        PHONE: 'Phone number',
        ADDRESS: 'Address',
        COUNTRY: 'Country',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm password',
        CONFIRM_PASSWORD_CHANGE: 'Confirm password',
        FIELD_REQUIRED: 'This field is required',
        CHANGE: 'Change',
        ACCOUNT_SETTINGS: 'Account settings',
        ROLES: 'Roles',
        EVENT_ATTENDANCE_CONFIRMATION: 'Event attendance confirmation',
        CLICK_TO_CONFIRM: 'Click to confirm',
        MEMBERSHIP: 'Membership',
        MEMBERSHIP_REQUIRED: 'Membership required',
        MEMBERSHIP_REQUIRED_DESCRIPTION:
            'You have not selected a membership. This is required to be able to use the system.',
        SCAN_THIS_CODE_TO_CONFIRM: 'Scan this code to confirm',
        EDUCATION: 'Education',
        TO_CHANGE_YOUR_PASSWORD_YOU_MUST_ENTER_YOUR_CURRENT_PASSWORD:
            'To change your password, you must confirm your current password below.',
        NOTIFICATIONS: 'Notifications',
        NEED_CONFIRMATION_FOR_EDUCATION: 'Need confirmation for education',
        PROFILE_NEWSLETTER_VOLUNTEER: 'I give consent to be informed about volunteer opportunities by email',
        PROFILE_NEWSLETTER_EVENTS: 'I give consent to be informed about events by email',
        PROFILE_NEWSLETTER_BENEFITS:
            'I give consent to be informed about benefits and discounts by email. This includes information about student discounts, benefits and offers from partners.',
        CLICK_TO_UNSUBSCRIBE: 'Click to unsubscribe',
        CLICK_TO_SUBSCRIBE: 'Click to subscribe',
        UNSUBSCRIBED: 'Unsubscribed',
        SUBSCRIBED: 'Subscribed',
        UPLOAD_PROFILE_PICTURE: 'Upload profile picture',
        SELECT_PROFILE_PICTURE: 'Select profile picture',
        PRIMARY_EMAIL: 'Primary email',
        SECONDARY_EMAIL: 'Secondary email',
        SECONDARY_EMAIL_HELPERTEXT: 'This is your private email',
        EMAIL_CHANGED_SUCCESS: 'Your email has been changed',
        CHANGE_EMAIL_TITLE: 'Change email',
        TO_CHANGE_YOUR_EMAIL_ADDRESS_PLEASE_ENTER_YOUR_NEW_EMAIL_ADDRESS_BELOW_AND_CLICK_CONFIRM:
            'To change your email address, please enter your new email address below and click confirm.',
        TYPE_YOUR_NEW_EMAIL_AGAIN: 'Type your new email again',
        YOU_WILL_BE_LOGGED_OUT_AFTER_CHANGING_YOUR_EMAIL: 'You will be logged out after changing your email',
        MEMBER_ID: 'Member ID',
        YOUR_COUNCIL: 'Your council',
        NO_COUNCIL_CONNECTED: 'You have not connected a council to your education. You can read more by',
        NO_COUNCIL_CLICK_HERE: 'clicking here',
        COUNCIL_FOR_YOUR_EDUCATION: 'Council for your education',
        COUNCIL_IS: 'is',
        COUNCIL_CLICK_TO_READ_MORE: 'Click to read more about your council',
        MEMBERSHIP_EXPIRE: 'Membership expire',
        PAYMENT_PROCESSING: 'Processing your payment. Please wait...',
        PAYMENT_REDIRECTING: 'You are now being redirected to the payment page. Please wait...',
        PAYMENT_SUCCESS: 'Thank you for your payment!',
        PAYMENT_ERROR_TITLE: 'Sorry',
        PAYMENT_ERROR_DESC_1: 'An error occurred during your payment.',
        PAYMENT_ERROR_DESC_2: 'Please try again.',
        PAYMENT_SELECT_SUBSCRIPTION_PLAN: 'Select membership',
        PAYMENT_SUBSCRIPTION_WILL_EXPIRE_AT: 'Your membership will expire at',
        PAYMENT_EDUCATION_REQUIRED: 'You need to select an education to continue',

        // Profile Page Alerts
        NEWSLETTER_SUCCESS_ENABLED: 'You are now subscribed to the newsletter',
        NEWSLETTER_SUCCESS_DISABLED: 'You are now unsubscribed from the newsletter',
        INFORMATION_CHANGED_SUCCESS: 'Your information has been changed',
        PASSWORD_CHANGED_SUCCESS: 'Your password has been changed',
        EDUCATION_REQUIRED: 'Education required',
        EDUCATION_CHANGED_DESCRIPTION:
            'You have not selected an education. This is required to be able to use the system.',
        SELECT_MEMBERSHIP: 'Select membership',
        LACK_OF_DECLARATION: 'Lack of declaration',
        LACK_OF_DECLARATION_DESCRIPTION:
            'You have not declared your education. This is required to be able to use the system.',
        PAYMENT_TITLE: 'Payment',
        PAYMENT_PENDING: 'Payment pending, please wait.',
        PAYMENT_AUTHORIZED: 'Payment authorized, please wait.',
        PAYMENT_CAPTURED: 'Payment received, thank you.',
        PAYMENT_ERROR: 'Payment error, please try again.',

        // Roles
        UNVERIFIED: 'Unverified',
        VERIFIED: 'Verified',
        MEMBER: 'Member',
        PARTNER: 'Partner',

        // COUPON
        COUPON_ADDED_TO_ACCOUNT: 'Coupon added to account',
        LINK_YOUR_ACCOUNT: 'Link your account',
        COUPON_APPLIED: 'Coupon applied',
        COUPON_NOT_APPLIED: 'Coupon not applied',
        COUPON_ERROR_TRY_AGAIN_LATER: 'An error occurred, please try again later',
        COUPON_REDEEM: 'SWIPE TO REDEEM',
        COUPON_REDEEM_ONLY_BY_STAFF: 'This coupon may only be redeemed by staff',
        COUPON_NOT_FOUND: 'Coupon not found',
        COUPON_NOT_FOUND_DESC: 'The coupon you are trying to find does not exist',
        COUPON_PROCESSING: 'Processing',
        COUPON_PROCESSING_DESC: 'Please wait while we process your coupon',
        COUPON_PAYMENT_ERROR: 'Payment error',
        COUPON_PAYMENT_ERROR_DESC: 'An error occurred during the payment process',

        // COUPONS
        COUPONS_YOUR_COUPONS: 'Your coupons',
        COUPONS_YOUR_COUPONS_DESC: 'Here you can see your active coupons',
        COUPONS_NO_COUPONS: 'You have no active coupons',
        COUPONS_BUY_HERE: 'Buy coupons here',
        COUPONS_USED: 'Used',
        COUPONS_USE: 'Use',
        COUPONS_EXPIRES: 'Expires',

        // COUPON SHOP
        COUPON_SHOP_BUY: 'Buy coupon',
        COUPON_SHOP_BUY_TEXT: 'You are about to buy a coupon',
        COUPON_SHOP_BUY_TEXT_2: 'with',
        COUPON_SHOP_BUY_TEXT_3: 'clips',
        COUPON_SHOP_WHEN_PURCHASED:
            'When you have purchased the coupon, you will receive a link to the coupon in your email.',
        COUPON_SHOP_BUY_FOR: 'Buy for',

        // EVENT ADMIN
        EVENT_ADMIN_SCAN_MEMBERS: 'Scan members',
        EVENT_ADMIN_USER_SCANNED: 'has been scanned in',
        EVENT_ADMIN_USER_ALREADY_SCANNED: 'has already been scanned in',
        EVENT_ADMIN_SCAN_WAITING: 'Waiting for scan',
        EVENT_ADMIN_NO_ACTIVE_EVENT: 'Der er ikke nogen aktiv begivenhed',

        // FORMS
        FORMS_NAME: 'Name',
        FORMS_DATE: 'Date',
        FORMS_GO_TO: 'Go to',

        // FORM
        FORM_ANSWER: 'Answer',
        FORM_NEW_ANSWER: 'New answer',
        FORM_ANSWERED_BEFORE: 'Answered before',
        FORM_ANSWERED_BEFORE_DESC: 'You have already answered this form. You can see your answers below.',
        FORM_ALREADY_ANSWERED: 'Already answered',
        FORM_ALREADY_ANSWERED_DESC: 'You have already answered this form.',
        FORM_MORE_THAN_ONE_ANSWER: 'You have answered this form more than once. You can see your answers below.',
        FORM_CHANGE_ANSWER: 'You can change your answer, by clicking on the answer on your right.',
        FORM_REQUIRE_LOGIN: 'You need to be logged in to answer this form.',
        FORM_NOT_ACCEPTING_ANSWERS: 'This form is not accepting answers at the moment.',
        FORM_INVALID_EMAIL: 'Invalid email',
        FORM_REQUIRED_FIELD: 'This field is required',
        FORM_EDIT_ANSWER: 'Edit answer',
        FORM_SUBMIT_ANSWER: 'Submit answer',

        // POST TYPES
        POST_TYPE_INTERNSHIP: 'Internship',
        POST_TYPE_STUDENT_JOB: 'Student job',
        POST_TYPE_FULL_TIME_JOB: 'Full time job',
        POST_TYPE_PROJECT: 'Project',

        // POSTS
        POSTS: 'Posts',
        POSTS_EDUCATION: 'Education',
        POSTS_ALL: 'All',
        POSTS_TYPE: 'Type',
        POSTS_SORTING: 'Sorting',
        POSTS_SORTING_NEWEST: 'Newest first',
        POSTS_SORTING_OLDEST: 'Oldest first',
        POSTS_SORTING_TITLE_AZ: 'Title A-Z',
        POSTS_SORTING_TITLE_ZA: 'Title Z-A',
        POSTS_NO_TO_SHOW: 'No posts to show',

        // POST PAGE
        POST_GO_BACK: 'Go back',
        POST_EDIT: 'Edit',
        POST_DELETE: 'Delete',

        // MY POSTS PAGE
        MY_POSTS: 'My posts',
        MY_POSTS_DELETED: 'The post has been deleted',
        MY_POSTS_VISIBILITY_SHOW: 'The post is now visible for users',
        MY_POSTS_VISIBILITY_HIDDEN: 'The post is now hidden for users',
        MY_POSTS_CREATE: 'Create post',
        MY_POSTS_TITLE: 'Title',
        MY_POSTS_TYPE: 'Type',
        MY_POSTS_EDUCATIONS: 'Educations',
        MY_POSTS_CREATED: 'Created',
        MY_POSTS_UPDATED: 'Updated',
        MY_POSTS_PREVIEW: 'Preview',
        MY_POSTS_HIDE: 'Hide post for users',
        MY_POSTS_SHOW: 'Show post for users',
        MY_POSTS_CONFIRM_DELETE: 'Confirm deletion',
        MY_POSTS_CONFIRM_DELETE_BUTTON: 'Delete post',
        MY_POSTS_ARE_YOU_SURE: 'Are you sure you want to delete the post ',
        MY_POSTS_ALL_EDUCATIONS: 'All educations',

        // MY POST PAGE
        MY_POST_CHANGES_NOT_SAVED: 'Changes are not saved yet. Do you want to leave the page?',
        MY_POST_CREATED: 'The post has been created',
        MY_POST_UPDATED: 'The post has been updated',
        MY_POST_CREATE: 'Create post',
        MY_POST_UPDATE: 'Update post',
        MY_POST_LANGUAGE: 'Language',
        MY_POST_PUBLISH: 'Publish post',
        MY_POST_NOT_PUBLISHED_YET_TITLE: 'Notice',
        MY_POST_NOT_PUBLISHED_YET_DESC:
            'The post is not published yet. You can publish the post by clicking the button on the right.',
        MY_POST_INFORMATION: 'Information',
        MY_POST_TITLE: 'Title',
        MY_POST_LOCATION: 'Location',
        MY_POST_SETTINGS: 'Settings',
        MY_POST_SAME_FOR_ALL_LANGUAGES: 'These settings are the same for all languages',
        MY_POST_TYPE: 'Type',
        MY_POST_EDUCATIONS: 'Educations',
        MY_POST_EDUCATIONS_HELPER: 'If you select no educations, the post will be visible for all educations',
        MY_POST_SHORT_DESCRIPTION: 'Short description',
        MY_POST_MAX_LENGTH: 'You have reached the maximum length',
        MY_POST_DESCRIPTION: 'Description',
        MY_POST_CREATE_BUTTON: 'Create post',
        MY_POST_SAVE_BUTTON: 'Save post'
    }
};

export default en;
