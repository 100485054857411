import './App.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import Layout from './layout/Layout';
import HomePage from './main/HomePage';
import LoginPage from './main/account/LoginPage';
import { selectUser } from './store/shared/authSlice';
import { selectPages } from './store/shared/configSlice';
import ContentPage from './main/ContentPage';
import LogoutPage from './main/account/LogoutPage';
import ProfilePage from './main/account/ProfilePage';
import RegisterPage from './main/account/RegisterPage';
import NewsScreenPage from './main/NewsScreenPage';
import ForgotPasswordPage from './main/account/ForgotPasswordPage';
import ResetPasswordPage from './main/account/ResetPasswordPage';
import Redirect from './ui-components/Redirect';
import EventPage from './main/EventPage';
import EmailPage from './main/account/EmailPage';
import CouncilAdminPage from './main/admin/CouncilAdminPage';
import CouncilPage from './main/CouncilPage';
import NotFoundPage from './main/NotFoundPage';
import SearchPage from './main/SearchPage';
import CouponsPage from './main/account/CouponsPage';
import CouponPage from './main/CouponPage';
import EventAdminPage from './main/admin/EventAdminPage';
import FormPage from './main/form/FormPage';
import PostPage from './main/post/PostPage';
import MyPostsPage from './main/post/MyPostsPage';
import MyPostPage from './main/post/MyPostPage';
import PartnerLoginPage from './main/account/PartnerLoginPage';

function App() {
    const user = useSelector(selectUser);
    const pages = useSelector(selectPages);

    const router = useMemo(() => {
        const authRoutes = [
            // {
            //     path: '/',
            //     index: true,
            //     element: <HomePage />,
            // },
        ];
        if (user) {
            authRoutes.push({
                auth: true,
                path: 'mine-opslag',
                element: <MyPostsPage />
            });
            authRoutes.push({
                auth: true,
                path: 'mine-opslag/:id',
                element: <MyPostPage />
            });
            authRoutes.push({
                auth: true,
                path: 'min-profil',
                element: <ProfilePage />
            });
            authRoutes.push({
                auth: true,
                path: '/council-admin/:slug',
                element: <CouncilAdminPage />
            });
            authRoutes.push({
                auth: true,
                path: '/event-admin',
                element: <EventAdminPage />
            });
            authRoutes.push({
                auth: true,
                path: 'mine-klippekort',
                element: <CouponsPage />
            });
        } else {
            authRoutes.push({
                path: 'min-profil',
                element: <Redirect to="/login" />
            });
        }
        authRoutes.push({
            path: 'form/:uid',
            element: <FormPage />
        });
        authRoutes.push({
            path: 'klippekort',
            element: <CouponPage />
        });
        authRoutes.push({
            path: 'klippekort/:uid',
            element: <CouponPage />
        });
        authRoutes.push({
            path: 'event/:id',
            element: <EventPage />
        });
        authRoutes.push({
            path: 'email',
            element: <EmailPage />
        });
        authRoutes.push({
            path: 'council/:slug',
            element: <CouncilPage />
        });
        authRoutes.push({
            path: 'opslag/:id',
            element: <PostPage />
        });
        authRoutes.push({ path: 'search', element: <SearchPage /> });
        pages.forEach(({ id, attributes: page }) => {
            authRoutes.push({
                id: `page-${id}`,
                path: `/${page.uri}`,
                element: <ContentPage />
            });
        });

        return createBrowserRouter([
            {
                path: 'login',
                element: <LoginPage />
            },
            {
                path: 'partner-login',
                element: <PartnerLoginPage />
            },
            {
                path: 'register',
                element: <RegisterPage />
            },
            {
                path: 'forgot-password',
                element: <ForgotPasswordPage />
            },
            {
                path: 'reset-password',
                element: <ResetPasswordPage />
            },
            {
                path: 'logout',
                element: <LogoutPage />
            },
            {
                path: 'news-screen',
                element: <NewsScreenPage />
            },
            {
                id: 'root',
                path: '/',
                Component: Layout,
                children: [
                    ...authRoutes,
                    // {
                    //     path: "login",
                    //     action: loginAction,
                    //     loader: loginLoader,
                    //     Component: LoginPage,
                    // },
                    // {
                    //     path: "protected",
                    //     loader: protectedLoader,
                    //     Component: ProtectedPage,
                    // },
                    {
                        path: '*',
                        element: <NotFoundPage />
                    }
                ]
            }
        ]);
    }, [pages, user]);

    return <RouterProvider router={router} />;
}

export default App;
