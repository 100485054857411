import { Outlet } from 'react-router-dom';
import LayoutHeader from './LayoutHeader';
import SnackMessage from '../ui-components/SnackMessage';
import ScrollToTop from './ScrollToTop';

export default function Layout() {
    return (
        <div>
            <LayoutHeader />
            <ScrollToTop />
            <div
                style={{
                    paddingTop: '95px'
                }}
            >
                <Outlet />
            </div>
            <SnackMessage />
        </div>
    );
}
