import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import apiService from '../../services/apiService';
import { requestConfig } from './configSlice';
import { showMessage } from './messageSlice';

export const logoutUser = () => async (dispatch, getState) => {
    localStorage.removeItem('jwt_token');
    dispatch(setUser(null));
    dispatch(requestConfig());
};

export const validate = () => async dispatch => {
    if (!apiService.getAccessToken()) {
        dispatch(setLoaded(true));
        return;
    }
    const response = await apiService
        .get(
            `/users/me?populate[0]=role&populate[1]=education&populate[2]=subscription_plan&populate[3]=admin_for_councils&populate[4]=education.council&populate[5]=education.council.name&populate[6]=education.name&populate[7]=admin_for_councils.name&populate[8]=coupons&populate[9]=coupons.coupon_type&populate[10]=coupons.coupon_type.name&populate[11]=coupons.coupon_type.description&populate[12]=coupons.coupon_type.colors`
        )
        .catch(() => null);
    if (!response) {
        dispatch(setLoaded(true));
        dispatch(logoutUser());
        return;
    }
    dispatch(setLoaded(true));
    dispatch(
        setUser({
            ...response,
            isPartnerPaid:
                response.membershipExpiration && moment(response.membershipExpiration).endOf('day').isAfter(moment())
        })
    );
};

export const createUser = (username, email, password, firstname, lastname, education) => async dispatch => {
    apiService
        .post(`/auth/local/register`, {
            username,
            email,
            password,
            firstname,
            lastname,
            education
        })
        .then(response => {
            dispatch(setAuthError(null));
            dispatch(setRegisterSuccess(response));
        })
        .catch(error => {
            dispatch(setAuthError({ message: error.response.data.error.message }));
        });
};

export const login = (identifier, password) => async dispatch => {
    apiService
        .post(`/auth/foreningsadministrator`, {
            identifier,
            password
        })
        .then(response => {
            localStorage.setItem('jwt_token', response.jwt);
            dispatch(setAuthError(null));
            dispatch(validate());
            dispatch(requestConfig());
            dispatch(
                showMessage({
                    message: 'Du er nu logget ind',
                    variant: 'success'
                })
            );
        })
        .catch(error => {
            if (error.response.data.error.message === 'Invalid identifier or password') {
                dispatch(setAuthError({ message: 'INVALID_PASSWORD' }));
            } else if (error.response.data.error.message === 'Your account email is not confirmed') {
                dispatch(setAuthError({ message: 'NOT_CONFIRMED' }));
            } else {
                dispatch(setAuthError({ message: error.response.data.error.message }));
            }
        })
        .finally(() => {
            dispatch(setRegisterSuccess(null));
        });
};
export const loginWithLocal = (identifier, password) => async dispatch => {
    apiService
        .post(`/auth/local`, {
            identifier,
            password
        })
        .then(response => {
            localStorage.setItem('jwt_token', response.jwt);
            dispatch(setAuthError(null));
            dispatch(validate());
            dispatch(requestConfig());
            dispatch(
                showMessage({
                    message: 'Du er nu logget ind',
                    variant: 'success'
                })
            );
        })
        .catch(error => {
            if (error.response.data.error.message === 'Invalid identifier or password') {
                dispatch(setAuthError({ message: 'INVALID_PASSWORD' }));
            } else if (error.response.data.error.message === 'Your account email is not confirmed') {
                dispatch(setAuthError({ message: 'NOT_CONFIRMED' }));
            } else {
                dispatch(setAuthError({ message: error.response.data.error.message }));
            }
        })
        .finally(() => {
            dispatch(setRegisterSuccess(null));
        });
};

export const resendConfirmation = email => async dispatch => {
    apiService
        .post(`/auth/send-email-confirmation`, {
            email
        })
        .then(response => {
            dispatch(setAuthError(null));
            dispatch(setRegisterSuccess(response));
        })
        .catch(error => {
            dispatch(setAuthError({ message: error.response.data.error.message }));
        });
};

export const sendResetPasswordLink = email => async dispatch => {
    apiService
        .post(`/auth/forgot-password`, {
            email
        })
        .then(response => {
            dispatch(setAuthError(null));
            dispatch(setForgotPasswordSuccess(response));
        })
        .catch(error => {
            if (error.response.data.error.message === 'User not confirmed') {
                dispatch(setAuthError({ message: 'NOT_CONFIRMED' }));
            } else {
                dispatch(setAuthError({ message: error.response.data.error.message }));
            }
        });
};

export const resetPassword = (code, password, passwordConfirmation) => async dispatch => {
    apiService
        .post(`/auth/reset-password`, {
            code,
            password,
            passwordConfirmation
        })
        .then(response => {
            localStorage.setItem('jwt_token', response.jwt);
            dispatch(setAuthError(null));
            dispatch(validate());
            dispatch(requestConfig());
        })
        .catch(error => {
            dispatch(setAuthError({ message: error.response.data.error.message }));
        });
};

export const updateUserData = user => async (dispatch, getState) => {
    if (!user.role || !user.data.displayName) {
        // is guest
        return;
    }
    apiService
        .updateUserData(user)
        .then(() => {
            // dispatch(showMessage({ message: 'Dine indstillinger er gemt', variant: 'success' }));
        })
        .catch(error => {
            // dispatch(showMessage({ message: error.message, variant: 'error' }));
        });
};

const initialState = {
    user: null,
    loaded: false,
    loginError: null,
    registerSuccess: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        setAuthError: (state, action) => {
            state.authError = action.payload;
        },
        setRegisterSuccess: (state, action) => {
            state.registerSuccess = action.payload;
        },
        setForgotPasswordSuccess: (state, action) => {
            state.forgotPasswordSuccess = action.payload;
        },
        setResetPasswordSuccess: (state, action) => {
            state.resetPasswordSuccess = action.payload;
        }
    }
});

export const {
    setUser,
    setLoaded,
    setAuthError,
    setRegisterSuccess,
    setForgotPasswordSuccess,
    setResetPasswordSuccess
} = authSlice.actions;

export default authSlice.reducer;

export const selectUser = state => state.auth.user;
export const selectLoaded = state => state.auth.loaded;
export const selectAuthError = state => state.auth.authError;
export const selectRegisterSuccess = state => state.auth.registerSuccess;
export const selectForgotPasswordSuccess = state => state.auth.forgotPasswordSuccess;
export const selectResetPasswordSuccess = state => state.auth.resetPasswordSuccess;
