import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Coupon from './account/Coupon';
import apiService from '../services/apiService';
import { showMessage } from '../store/shared/messageSlice';

export default function CouponPage() {
    const { uid } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [notFound, setNotFound] = useState(null);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [errorPayment, setErrorPayment] = useState(false);
    const { t } = useTranslation();
    const requestOnce = useRef(false);

    const requestCoupon = useCallback(() => {
        if (uid) {
            apiService
                .get(`/coupon/anonymous/${uid}`)
                .then(response => {
                    setSelectedCoupon(response);
                    setNotFound(false);
                })
                .catch(() => setNotFound(true));
        }
    }, [uid]);

    useEffect(() => {
        requestCoupon();
    }, [requestCoupon]);

    useEffect(() => {
        const params = searchParams.toString();
        if (params && !requestOnce.current) {
            setLoadingPayment(true);
            setErrorPayment(false);
            requestOnce.current = true;
            apiService
                .get(`/payment-callback?${params}`)
                .then(response => {
                    setLoadingPayment(false);
                    if (response.coupon.user) {
                        window.location.href = '/mine-klippekort';
                    } else if (response.coupon.uid) {
                        window.location.href = `/klippekort/${response.coupon.uid}`;
                    }
                })
                .catch(e => {
                    if (e.response.data.error.message === 'Payment already processed') {
                        setLoadingPayment(false);
                        if (e.response.data.error.details?.response?.coupon?.user) {
                            window.location.href = '/mine-klippekort';
                        } else if (e.response.data.error.details?.coupon?.uid) {
                            window.location.href = `/klippekort/${e.response.data.error.details.coupon.uid}`;
                        } else {
                            dispatch(showMessage({ message: t('PAYMENT_ERROR'), variant: 'error' }));
                            setErrorPayment(true);
                        }
                    } else {
                        dispatch(showMessage({ message: t('PAYMENT_ERROR'), variant: 'error' }));
                        setErrorPayment(true);
                    }
                });
        }
    }, [t, searchParams, navigate, dispatch]);

    return (
        <div className="h-screen bg-[#E5E7EB] py-10">
            <div className="max-w-2xl px-4 md:px-6 mx-auto">
                <Card className="md:px-0 px-4">
                    {loadingPayment && !errorPayment && (
                        <div className="flex flex-col items-center py-10">
                            <Typography variant="h6" className="text-2xl">
                                {t('COUPON_PROCESSING')}
                            </Typography>
                            <Typography>{t('COUPON_PROCESSING_DESC')}</Typography>
                        </div>
                    )}
                    {errorPayment && (
                        <div className="flex flex-col items-center py-10">
                            <Typography variant="h6" className="text-2xl">
                                {t('COUPON_PAYMENT_ERROR')}
                            </Typography>
                            <Typography>{t('COUPON_PAYMENT_ERROR_DESC')}</Typography>
                        </div>
                    )}
                    {!loadingPayment && !errorPayment && notFound && (
                        <div className="flex flex-col items-center py-10">
                            <Typography variant="h6" className="text-2xl">
                                {t('COUPON_NOT_FOUND')}
                            </Typography>
                            <Typography>{t('COUPON_NOT_FOUND_DESC')}</Typography>
                        </div>
                    )}
                    {selectedCoupon && (
                        <Coupon selectedCoupon={selectedCoupon} onUpdate={() => requestCoupon()} canConnect />
                    )}
                </Card>
            </div>
        </div>
    );
}
