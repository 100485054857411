import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    Link as MUILink,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../services/apiService';
import { selectUser, validate } from '../../store/shared/authSlice';
import { showMessage } from '../../store/shared/messageSlice';
import Loading from '../../ui-components/Loading';
import {
    selectPageForPartnerTerms,
    selectPartnerDays,
    selectPartnerPrice,
    selectPartnerTax
} from '../../store/shared/configSlice';

const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

export default function PartnerPaymentDialog({ open, onClose }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const termsPage = useSelector(selectPageForPartnerTerms);
    const user = useSelector(selectUser);
    const partnerPrice = useSelector(selectPartnerPrice);
    const partnerDays = useSelector(selectPartnerDays);
    const partnerTax = useSelector(selectPartnerTax);
    const [waiting, setWaiting] = useState(false);
    const [payment, setPayment] = useState(null);
    const [paymentInterval, setPaymentInterval] = useState(null);
    const { handleSubmit, reset, control, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            termsAccepted: false
        }
    });
    const { isValid, isSubmitting, isSubmitted } = formState;
    const { t } = useTranslation();

    const finished = useMemo(() => {
        return ['captured', 'cancelled', 'refunded', 'error'].includes(payment?.state);
    }, [payment?.state]);

    useEffect(() => {
        const params = searchParams.toString();
        if (params) {
            setWaiting(true);
            apiService
                .get(`/payment-callback?${params}`)
                .then(() => {
                    dispatch(validate());
                    dispatch(showMessage({ message: t('PAYMENT_SUCCESS'), variant: 'success' }));
                    setWaiting(false);
                    onClose();
                })
                .catch(console.error);
        }
    }, [t, dispatch, searchParams, onClose]);

    useEffect(() => {
        if (!paymentInterval) {
            return () => {};
        }
        const interval = setInterval(() => {
            apiService
                .get(`/payments/${paymentInterval.id}?accessToken=${paymentInterval.accessToken}`)
                .then(response => {
                    if (response?.data?.attributes?.state === 'pending' && response.data.attributes.payment_link) {
                        window.open(response.data.attributes.payment_link, '_self');
                    } else {
                        setPayment(response.data.attributes);
                    }
                })
                .catch(console.error);
        }, 1000);
        return () => clearInterval(interval);
    }, [paymentInterval]);

    function handleClose() {
        onClose();
        setSearchParams({});
    }

    async function onSubmit(data) {
        const response = await apiService.post(`/users/payment`, data).catch(error => {
            return { error };
        });
        if (response.error) {
            dispatch(showMessage({ message: response.error.response.data.error.message, variant: 'error' }));
        }
        localStorage.setItem('payment', JSON.stringify(response));
        setPaymentInterval(response);
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Køb partner</DialogTitle>
                <IconButton
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    <Icon>close</Icon>
                </IconButton>
                <DialogContent dividers>
                    {waiting && (
                        <Loading
                            fullWidth
                            style={{
                                minHeight: '10rem'
                            }}
                            text={t('PAYMENT_PROCESSING')}
                        />
                    )}
                    {!waiting && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    Du er igang med at købe partner i{' '}
                                    <span className="font-bold">{partnerDays} dage</span>.
                                </Typography>
                                <Typography>
                                    Når betalingen er godkendt, vil du også modtage en bekræftelse på mail.
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="termsAccepted"
                                    rules={{
                                        required: t('FORM_REQUIRED_FIELD')
                                    }}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={<Checkbox checked={field.value} />}
                                            onChange={field.onChange}
                                            disabled={isSubmitting || isSubmitted}
                                            label={
                                                <Typography>
                                                    Jeg accepterer{' '}
                                                    <MUILink href={termsPage?.data?.attributes?.uri} target="_blank">
                                                        handelsbetingelserne
                                                    </MUILink>
                                                </Typography>
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className="flex items-end flex-col">
                                    <div className="flex items-center">
                                        <Typography color="textSecondary" className="mr-1">
                                            I alt
                                        </Typography>
                                        <Typography className="font-bold">{formatter.format(partnerPrice)}</Typography>
                                    </div>
                                    <Typography className="text-xs opacity-75">
                                        Heraf {partnerTax}% moms:{' '}
                                        <span className="font-semibold">
                                            {formatter.format((partnerPrice * partnerTax) / (100 + partnerTax))}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                {!waiting && (
                    <DialogActions>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            loading={isSubmitting || isSubmitted}
                        >
                            Gå til betaling
                        </LoadingButton>
                    </DialogActions>
                )}
            </form>
        </Dialog>
    );
}
