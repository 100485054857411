import { Alert, Button, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment/moment';
import { Link, useSearchParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import apiService from '../services/apiService';
import allLanguages from '../translation/languages';

export default function SearchPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const [results, setResults] = useState([]);
    const [pages, setPages] = useState([]);
    const [events, setEvents] = useState([]);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        apiService.get('/pages?populate[0]=title&pagination[limit]=1000000000&noLanguageFormat=true').then(response => {
            setPages(response.data);
        });
        apiService.get('/events?populate=title&pagination[limit]=1000000000&noLanguageFormat=true').then(response => {
            setEvents(response.data);
        });
        apiService.get('/reports?populate=pdf&pagination[limit]=1000000000&noLanguageFormat=true').then(response => {
            setReports(response.data);
        });
    }, []);

    console.log({ results, pages, events, reports });

    const fuse = useMemo(() => {
        return new Fuse(
            [
                ...pages.map(item => ({ ...item, _type: 'page' })),
                ...events.map(item => ({ ...item, _type: 'event' })),
                ...reports.map(item => ({ ...item, _type: 'report' }))
            ],
            {
                keys: [
                    'attributes.name',
                    'attributes.title',
                    'attributes.start',
                    'attributes.uri',
                    'attributes.search_text'
                ]
            }
        );
    }, [pages, events, reports]);

    useEffect(() => {
        if (searchParams.get('q')) {
            console.log(`Searching for ${searchParams.get('q')}`);
            setResults(fuse.search(searchParams.get('q')).map(i => i.item));
        }
    }, [fuse, searchParams]);

    const totalResults = useMemo(() => {
        return results.length;
    }, [results]);

    const currentLanguage = useMemo(() => {
        return Object.values(allLanguages).find(language => {
            return language.isoCode === i18n.language;
        });
    }, [i18n.language]);

    return (
        <div className="min-h-screen bg-[#E5E7EB] py-10">
            <div className="max-w-3xl px-4 md:px-6 mx-auto">
                <Typography className="mb-10 text-center text-xl">
                    {t('SEARCH_RESULTS_FOR')} <strong>{searchParams.get('q')}</strong>
                </Typography>
                {totalResults === 0 && (
                    <Alert severity="warning" className="">
                        {t('SEARCH_NO_RESULTS')}
                    </Alert>
                )}
                <div className="flex flex-col gap-4">
                    {results?.map(result => (
                        <>
                            {result._type === 'event' && (
                                <Card className="p-2 rounded-lg shadow-lg">
                                    <CardContent>
                                        <div className={clsx('flex justify-between items-start')}>
                                            <Typography className="text-lg font-bold">
                                                {result.attributes.name}
                                            </Typography>
                                            <Typography
                                                className={clsx(
                                                    'text-xs font-medium me-2 px-2.5 py-0.5 rounded text-nowrap min-w-[5.8rem] ml-2',
                                                    'bg-primary text-white'
                                                )}
                                            >
                                                {moment(result.attributes.start).format(t('MEDIUM_DATE_FORMAT'))}
                                            </Typography>
                                        </div>
                                        {result.attributes.location && (
                                            <Typography className="text-sm italic text-ellipsis">
                                                {result.attributes.location}
                                            </Typography>
                                        )}
                                        <Typography className="text-sm mt-4 mb-4 overflow-hidden" color="textSecondary">
                                            {result.attributes.description && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.attributes.description
                                                    }}
                                                    className="ck-content"
                                                />
                                            )}
                                            {!result.attributes.description && t('NO_DESCRIPTION')}
                                        </Typography>
                                        {result.attributes.link && (
                                            <Button component={Link} to={result.attributes.link} variant="contained">
                                                {t('SIGN_UP')}
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            )}
                            {result._type === 'page' && (
                                <Card className="p-2 rounded-lg shadow-lg">
                                    <CardContent>
                                        <div className={clsx('flex justify-between items-start')}>
                                            <Typography className="text-lg font-bold">
                                                {result.attributes.title?.[currentLanguage.translationKey]}
                                            </Typography>
                                        </div>
                                        <Button
                                            component={Link}
                                            to={`${result.attributes.uri}`}
                                            variant="outlined"
                                            size="small"
                                            className="mt-4"
                                        >
                                            {t('PAGE_SHOW_MORE_BUTTON')}
                                        </Button>
                                    </CardContent>
                                </Card>
                            )}
                            {result._type === 'report' && (
                                <Card className="p-2 rounded-lg shadow-lg">
                                    <CardContent>
                                        <div className={clsx('flex justify-between items-start')}>
                                            <Typography className="text-lg font-bold">
                                                {result.attributes.name}
                                            </Typography>
                                            <Typography
                                                className={clsx(
                                                    'text-xs font-medium me-2 px-2.5 py-0.5 rounded text-nowrap min-w-[5.8rem]',
                                                    'bg-primary text-white'
                                                )}
                                            >
                                                {moment(result.attributes.createdAt).format(t('MEDIUM_DATE_FORMAT'))}
                                            </Typography>
                                        </div>
                                        {result.attributes?.pdf?.data?.attributes?.url && (
                                            <Button
                                                onClick={() => {
                                                    window.open(
                                                        apiService.getImage(result.attributes.pdf.data.attributes.url),
                                                        '_blank'
                                                    );
                                                }}
                                                variant="outlined"
                                                size="small"
                                                className="mt-4"
                                            >
                                                {t('SHOW_PDF_BUTTON')}
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}
