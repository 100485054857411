import {
    Badge,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Typography
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useSelector } from 'react-redux';
import apiService from '../../services/apiService';
import { selectUser } from '../../store/shared/authSlice';

export default function PostsComponent({ pageContent }) {
    const user = useSelector(selectUser);
    const [sorting, setSorting] = useState('createdAt:desc');
    const [posts, setPosts] = useState([]);
    const [selectedType, setSelectedType] = useState('all');
    const [selectedEducation, setSelectedEducation] = useState(user?.education?.id || 'all');
    const [educations, setEducations] = useState(null);
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(null);

    const sortings = useMemo(() => {
        let language = 'danish';
        if (i18n.language === 'en') {
            language = 'english';
        }
        return [
            { label: t('POSTS_SORTING_NEWEST'), value: 'createdAt:desc' },
            { label: t('POSTS_SORTING_OLDEST'), value: 'createdAt:asc' },
            { label: t('POSTS_SORTING_TITLE_AZ'), value: `title.${language}:asc` },
            { label: t('POSTS_SORTING_TITLE_ZA'), value: `title.${language}:desc` }
        ];
    }, [t, i18n]);

    function handlePageChange(event, value) {
        setPage(value);
    }

    useEffect(() => {
        let filters = '';
        if (selectedType !== 'all') {
            filters += `&filters[$and][0][type]=${selectedType}`;
        }
        if (selectedEducation !== 'all') {
            filters += `&filters[$and][1][$or][0][educations]=${selectedEducation}`;
            filters += `&filters[$and][1][$or][1][educations][$null]=true`;
        }
        apiService
            .get(
                `/posts?sort=${sorting}&populate[0]=title&populate[1]=location&populate[2]=short_description&populate[3]=createdAt&populate[4]=updatedAt&populate[5]=educations&populate[6]=educations.name&pagination[pageSize]=20&pagination[page]=${page}${filters}&defaultLocale=da`
            )
            .then(response => {
                setPosts(response.data);
                setMaxPages(response.meta.pagination.pageCount);
            });
    }, [selectedType, selectedEducation, page, sorting]);

    useEffect(() => {
        apiService
            .get(`/educations?sort=name.danish&populate[0]=name&pagination[pageSize]=1000000&defaultLocale=da`)
            .then(response => {
                setEducations(response.data);
            });
    }, []);

    function handleEducationChange(education) {
        setSelectedEducation(education);
    }

    function handleTypeChange(type) {
        setSelectedType(type);
    }

    function handleSortChange(sort) {
        setSorting(sort);
    }

    return (
        <div
            className="container space-y-12 px-4 md:px-6 mx-auto"
            style={{
                paddingBottom: '10rem'
            }}
        >
            <div className="flex justify-between items-center pt-6">
                <Typography className="text-3xl font-bold tracking-tighter">{t('POSTS')}</Typography>
                <div className="flex flex-wrap justify-end">
                    <FormControl sx={{ m: 1, width: 160 }} size="small">
                        <InputLabel id="select-edu">{t('POSTS_EDUCATION')}</InputLabel>
                        <Select
                            labelId="select-edu"
                            value={selectedEducation ?? 'all'}
                            label={t('POSTS_EDUCATION')}
                            onChange={e => handleEducationChange(e.target.value)}
                        >
                            <MenuItem key="all" value="all">
                                {t('POSTS_ALL')}
                            </MenuItem>
                            {educations?.map(education => (
                                <MenuItem key={`education-${education.id}`} value={education.id}>
                                    {education.attributes.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 120 }} size="small">
                        <InputLabel id="select-type">{t('POSTS_TYPE')}</InputLabel>
                        <Select
                            labelId="select-type"
                            value={selectedType ?? 'all'}
                            label={t('POSTS_TYPE')}
                            onChange={e => handleTypeChange(e.target.value)}
                        >
                            <MenuItem key="all" value="all">
                                {t('POSTS_ALL')}
                            </MenuItem>
                            {['STUDENT_JOB', 'FULL_TIME_JOB', 'INTERNSHIP', 'PROJECT'].map(type => (
                                <MenuItem key={`type-${type}`} value={type}>
                                    {t(`POST_TYPE_${type.toUpperCase()}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 160 }} size="small">
                        <InputLabel id="select-edu">{t('POSTS_SORTING')}</InputLabel>
                        <Select
                            labelId="select-edu"
                            value={sorting}
                            label={t('POSTS_SORTING')}
                            onChange={e => handleSortChange(e.target.value)}
                        >
                            {sortings.map(sort => (
                                <MenuItem key={`sort-${sort.value}`} value={sort.value}>
                                    {sort.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Grid container spacing={4} className="!-mt-2">
                {posts.length === 0 && (
                    <Grid item xs={12}>
                        <div className="flex justify-center items-center flex-col p-20">
                            <SentimentDissatisfiedIcon fontSize="inherit" style={{ fontSize: '6rem' }} />
                            <Typography className="mt-4 text-3xl font-bold">{t('POSTS_NO_TO_SHOW')}</Typography>
                        </div>
                    </Grid>
                )}
                {posts.map(post => (
                    <Grid item sm={6} md={4} lg={3} className="w-full">
                        <Link
                            to={`/opslag/${post.id}`}
                            style={{
                                color: 'unset',
                                cursor: 'unset',
                                textDecoration: 'unset'
                            }}
                        >
                            <Card className={clsx('rounded-lg h-full cursor-pointer')}>
                                <CardContent className="relative">
                                    <div className={clsx('flex justify-between items-start')}>
                                        <Typography className="text-lg font-bold">{post.attributes.title}</Typography>
                                        <Typography
                                            className={clsx(
                                                'text-xs font-medium px-2.5 py-0.5 rounded text-nowrap bg-primary text-white'
                                            )}
                                        >
                                            {t(`POST_TYPE_${post.attributes.type.toUpperCase()}`)}
                                        </Typography>
                                    </div>
                                    <div className="flex justify-between items-start">
                                        <Typography className="text-sm italic text-ellipsis">
                                            {post.attributes.location}
                                        </Typography>
                                        <Typography className={clsx('text-xs italic text-ellipsis')}>
                                            {moment(post.attributes.createdAt).format(t('MEDIUM_DATE_FORMAT'))}
                                        </Typography>
                                    </div>
                                    <Typography
                                        className="text-sm mt-4 mb-[2rem] overflow-hidden min-h-[3rem]"
                                        color="textSecondary"
                                    >
                                        {post.attributes.short_description &&
                                            !post.attributes.short_description?.id && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: post.attributes.short_description
                                                    }}
                                                    className="ck-content"
                                                />
                                            )}
                                        {(!post.attributes.short_description ||
                                            post.attributes.short_description?.id) &&
                                            t('NO_DESCRIPTION')}
                                    </Typography>
                                    <div className="absolute bottom-[1rem]">
                                        <div className="flex flex-wrap gap-2">
                                            {post.attributes.educations.data.length === 0 && (
                                                <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                                    {t('MY_POSTS_ALL_EDUCATIONS')}
                                                </Badge>
                                            )}
                                            {post.attributes.educations.data.map(education => (
                                                <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                                    {education.attributes.name}
                                                </Badge>
                                            ))}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                ))}
                {posts.length !== 0 && (
                    <Grid item xs={12}>
                        <div className="flex justify-center mt-4">
                            <Pagination count={maxPages} page={page} onChange={handlePageChange} />
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
