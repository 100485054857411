import { useParams } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import FormComponent from './FormComponent';

export default function FormPage() {
    const { uid } = useParams();

    if (!uid) {
        return <NotFoundPage />;
    }

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 95px)'
            }}
        >
            <FormComponent
                pageContent={{
                    form: { data: { attributes: { uid } } }
                }}
            />
        </div>
    );
}
