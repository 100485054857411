import clsx from 'clsx';
import apiService from '../services/apiService';

export default function HeroImageComponent({ pageContent }) {
    return (
        <section
            className={clsx('w-full py-12 bg-gray-900 text-white hero-image', `hero-image-${pageContent.id}`)}
            style={{
                backgroundColor: pageContent?.colors?.backgroundColor
            }}
        >
            <style>
                {`
                    .hero-image-${pageContent.id}:before {
                        background-image: url(${apiService.getImage(pageContent?.heroImage?.data?.attributes?.url)});
					}
				`}
            </style>
            <div className="container px-4 md:px-6 mx-auto hero-content pb-20">
                <div className="flex flex-col justify-center space-y-4">
                    {(pageContent.title || pageContent.title2) && (
                        <h1
                            className="text-3xl font-bold tracking-tight sm:text-5xl xl:text-6xl"
                            style={{
                                color: pageContent?.colors?.textColor || '#fff'
                            }}
                        >
                            {pageContent.title}
                            <br />
                            {pageContent.title2}
                        </h1>
                    )}
                    {pageContent.subtitle && (
                        <p
                            className="max-w-[600px] md:text-xl"
                            style={{
                                color: pageContent?.colors?.textColor || '#fff'
                            }}
                        >
                            {pageContent.subtitle}
                        </p>
                    )}
                </div>
            </div>
        </section>
    );
}
