import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, DialogContentText, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Scanner } from '@yudiel/react-qr-scanner';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import { selectUser } from '../../store/shared/authSlice';
import apiService from '../../services/apiService';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';

export default function EventAdminPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const disableScan = useRef(false);
    const [qrCodes, setQrCodes] = useState([]);
    const authUser = useSelector(selectUser);
    const { t } = useTranslation();

    const hasAccess = useMemo(() => {
        return authUser?.access_to_scan_members;
    }, [authUser]);

    useEffect(() => {
        if (!hasAccess) {
            navigate('/min-profil');
        }
    }, [hasAccess, navigate]);

    useEffect(() => {
        if (qrCodes?.length >= 2 && !disableScan.current) {
            apiService
                .post('/event/scan', { tokens: qrCodes })
                .then(resp => {
                    disableScan.current = true;
                    setResponse(resp);
                })
                .catch(console.error);
        }
    }, [dispatch, qrCodes]);

    function handleScan(data) {
        const qrCode = data?.[0]?.rawValue;
        if (qrCode) {
            setQrCodes(oldState => {
                const _oldState = _.cloneDeep(oldState);
                if (_oldState.length >= 2) {
                    _oldState.splice(0, 1);
                }
                return [..._oldState, qrCode];
            });
        }
    }

    function handleClose() {
        setQrCodes([]);
        setResponse(null);
        disableScan.current = false;
    }

    return (
        <div className="w-full min-h-screen bg-gray-200 pt-10 p-2 md:p-10">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h2 className="text-2xl text-center font-bold py-0 -mt-2 mb-2">{t('EVENT_ADMIN_SCAN_MEMBERS')}</h2>
                </Grid>
                <Grid item xs={12}>
                    <div className="flex justify-center">
                        <Paper className="p-6">
                            <div className="w-full sm:w-96">
                                <Scanner
                                    onScan={handleScan}
                                    components={{
                                        audio: false,
                                        finder: false
                                    }}
                                />
                            </div>
                            <ConfirmationDialog
                                open={!!response}
                                onClose={handleClose}
                                title={t('EVENT_ADMIN_SCAN_MEMBERS')}
                                okButton={t('CLOSE')}
                                cancelButton={false}
                            >
                                <DialogContentText>
                                    {!!response?.ok && (
                                        <Alert severity="success" className="-mx-6 rounded-t-none">
                                            <Typography variant="body1" className="text-center">
                                                {response?.user?.firstname} {response?.user?.lastname}{' '}
                                                {t('EVENT_ADMIN_USER_SCANNED')}
                                            </Typography>
                                        </Alert>
                                    )}
                                    {!response?.ok && response?.error === 'alreadyAttended' && (
                                        <Alert severity="error" className="-mx-6 rounded-t-none">
                                            <Typography variant="body1" className="text-center">
                                                {t('EVENT_ADMIN_USER_ALREADY_SCANNED')}
                                            </Typography>
                                        </Alert>
                                    )}
                                    {!response?.ok && response?.error === 'noActiveEvent' && (
                                        <Alert severity="error" className="-mx-6 rounded-t-none">
                                            <Typography variant="body1" className="text-center">
                                                {t('EVENT_ADMIN_NO_ACTIVE_EVENT')}
                                            </Typography>
                                        </Alert>
                                    )}
                                </DialogContentText>
                            </ConfirmationDialog>
                            {!response?.ok &&
                                response?.error !== 'alreadyAttended' &&
                                response?.error !== 'noActiveEvent' && (
                                    <Alert severity="info" className="mt-6 -mx-6 -mb-6 rounded-t-none">
                                        <Typography variant="body1" className="text-center">
                                            {t('EVENT_ADMIN_SCAN_WAITING')}
                                        </Typography>
                                    </Alert>
                                )}
                        </Paper>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
