import { useMemo } from 'react';
import { Grid } from '@mui/material';
import apiService from '../services/apiService';

export default function MediasComponent({ pageContent }) {
    const images = useMemo(() => {
        return pageContent?.files?.map(media => ({
            url: apiService.getImage(media.image.data.attributes.url),
            height: media.height,
            width: media.width
        }));
    }, [pageContent]);

    return (
        <div>
            <section className="w-full py-12">
                <div className="container px-4 md:px-6 mx-auto">
                    <Grid container spacing={2}>
                        {images?.map(({ url, height, width }) => (
                            <Grid item xs={6} md={4} lg={3} className="">
                                <img
                                    src={url}
                                    style={{
                                        height: height ? `${height}` : '100%',
                                        width: width ? `${width}` : '100%'
                                    }}
                                    alt=""
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </section>
        </div>
    );
}
