import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    Link as MUILink,
    ListItemText,
    MenuItem,
    Select,
    Table,
    TableCell,
    TextField,
    Typography,
    useMediaQuery,
    TableRow,
    TableHead,
    TableBody,
    FormControlLabel,
    IconButton,
    Icon
} from '@mui/material';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { SwipeableButton } from 'react-swipeable-button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../services/apiService';
import { showMessage } from '../store/shared/messageSlice';
import { selectUser } from '../store/shared/authSlice';
import { selectPageForCouponTerms } from '../store/shared/configSlice';

const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

export default function CouponShopComponent({ pageContent }) {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const termsPage = useSelector(selectPageForCouponTerms);
    const [couponTypes, setCouponTypes] = useState([]);
    const [selectedCouponType, setSelectedCouponType] = useState(null);
    const { handleSubmit, reset, control, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            termsAccepted: false,
            email: user?.email
        }
    });
    const { isValid, isSubmitting, isSubmitted } = formState;
    const { t } = useTranslation();
    const [paymentInterval, setPaymentInterval] = useState(null);
    const [payment, setPayment] = useState(null);

    useEffect(() => {
        apiService
            .get(`/coupon-types?filters[buyable]=true&populate[0]=name&populate[1]=colors&populate[2]=description`)
            .then(response => {
                setCouponTypes(
                    response.data.map(e => ({
                        ...e,
                        ...e.attributes
                    }))
                );
            });
    }, []);

    function handleClickCouponType(couponType) {
        setSelectedCouponType(couponType);
    }

    async function onSubmit(data) {
        const response = await apiService.post(`/coupon-types/${selectedCouponType.id}/payment`, data).catch(error => {
            return { error };
        });
        if (response.error) {
            dispatch(showMessage({ message: response.error.response.data.error.message, variant: 'error' }));
        }
        localStorage.setItem('payment', JSON.stringify(response));
        setPaymentInterval(response);
    }

    useEffect(() => {
        if (!paymentInterval) {
            return () => {};
        }
        const interval = setInterval(() => {
            apiService
                .get(`/payments/${paymentInterval.id}?accessToken=${paymentInterval.accessToken}`)
                .then(response => {
                    if (response?.data?.attributes?.state === 'pending' && response.data.attributes.payment_link) {
                        window.open(response.data.attributes.payment_link, '_self');
                    } else {
                        setPayment(response.data.attributes);
                    }
                })
                .catch(console.error);
        }, 1000);
        return () => clearInterval(interval);
    }, [paymentInterval]);

    function handleClose() {
        setSelectedCouponType(null);
        reset();
    }

    return (
        <div
            className="container space-y-12 px-4 md:px-6 mx-auto"
            style={{
                paddingBottom: '10rem'
            }}
        >
            <div className="flex justify-between items-center pt-6">
                <Typography className="text-3xl font-bold tracking-tighter">{t('COUPON_SHOP_BUY')}</Typography>
            </div>
            <Dialog open={!!selectedCouponType} onClose={handleClose} maxWidth="sm" fullWidth>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{t('COUPON_SHOP_BUY')}</DialogTitle>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500]
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    {t('COUPON_SHOP_BUY_TEXT')}{' '}
                                    <span className="font-bold">{selectedCouponType?.name}</span>{' '}
                                    {t('COUPON_SHOP_BUY_TEXT_2')}{' '}
                                    <span className="font-bold">
                                        {selectedCouponType?.amount} {t('COUPON_SHOP_BUY_TEXT_3')}
                                    </span>
                                    .
                                </Typography>
                                <Typography>{t('COUPON_SHOP_WHEN_PURCHASED')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: t('FORM_REQUIRED_FIELD'),
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
                                            message: t('FORM_INVALID_EMAIL')
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            value={field.value || ''}
                                            required
                                            className="w-full"
                                            size="small"
                                            label={t('EMAIL')}
                                            error={fieldState.invalid}
                                            helperText={fieldState.error?.message}
                                            placeholder={t('YOUR_EMAIL')}
                                            disabled={isSubmitting || isSubmitted || user?.email}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="termsAccepted"
                                    rules={{
                                        required: t('FORM_REQUIRED_FIELD')
                                    }}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={<Checkbox checked={field.value} />}
                                            onChange={field.onChange}
                                            disabled={isSubmitting || isSubmitted}
                                            label={
                                                <Typography>
                                                    {t('I_ACCEPT')}{' '}
                                                    <MUILink href={termsPage?.data?.attributes?.uri} target="_blank">
                                                        {t('TERMS_AND_CONDITIONS')}
                                                    </MUILink>
                                                </Typography>
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            {!!selectedCouponType && (
                                <Grid item xs={4}>
                                    <div className="flex items-end flex-col">
                                        <div className="flex items-center">
                                            <Typography color="textSecondary" className="mr-1">
                                                {t('TOTAL')}
                                            </Typography>
                                            <Typography className="font-bold">
                                                {formatter.format(selectedCouponType.price)}
                                            </Typography>
                                        </div>
                                        <Typography className="text-xs opacity-75">
                                            {t('OF_THIS')} {selectedCouponType.tax_percent}% {t('TAX')}:{' '}
                                            <span className="font-semibold">
                                                {formatter.format(
                                                    (selectedCouponType.price * selectedCouponType.tax_percent) /
                                                        (100 + selectedCouponType.tax_percent)
                                                )}
                                            </span>
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            disabled={!isValid}
                            loading={isSubmitting || isSubmitted}
                            type="submit"
                        >
                            {t('GO_TO_PAYMENT')}
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
            <Grid container spacing={4} className="!-mt-2">
                {couponTypes.map(couponType => (
                    <Grid item sm={6} md={4} lg={3} className="w-full">
                        <Card className={clsx('rounded-lg')}>
                            <CardHeader
                                sx={{
                                    bgcolor: couponType?.colors?.backgroundColor || 'primary.main',
                                    '& .MuiCardHeader-title': {
                                        color: couponType?.colors?.textColor || 'primary.contrastText'
                                    }
                                }}
                                classes={{ title: 'font-bold text-2xl uppercase' }}
                                avatar={
                                    <Avatar
                                        sx={{
                                            bgcolor: couponType?.colors?.textColor || 'primary.contrastText',
                                            color: couponType?.colors?.backgroundColor || 'primary.main',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {couponType.amount}
                                    </Avatar>
                                }
                                title={couponType.name}
                            />
                            <CardContent className="-mt-2 -mb-4">
                                <div
                                    dangerouslySetInnerHTML={{ __html: couponType.description }}
                                    className="ck-content"
                                />
                            </CardContent>
                            <CardActions>
                                <Button
                                    className="w-full"
                                    color="success"
                                    variant="contained"
                                    onClick={() => handleClickCouponType(couponType)}
                                >
                                    {t('COUPON_SHOP_BUY_FOR')} {formatter.format(couponType.price)}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
