import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import clsx from 'clsx';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import { TableFooter, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import apiService from '../../services/apiService';
import FormComponent from './FormComponent';
import NotFoundPage from '../NotFoundPage';
import { selectUser } from '../../store/shared/authSlice';

export default function FormsPage({ pageContent }) {
    const navigate = useNavigate();
    const [forms, setForms] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const { t } = useTranslation();
    const user = useSelector(selectUser);

    const handleChangePage = (event, newPage) => {
        setTablePage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    useEffect(() => {
        let filter = '&filters[accept_answers]=true';
        if (pageContent?.exclude?.data?.length > 0) {
            filter = `&filters[id][$notIn]=${pageContent.exclude.data.map(form => form.id).join(',')}`;
        }
        if (pageContent?.shown_forms?.data?.length > 0) {
            filter = `&filters[id][$in]=${pageContent.shown_forms.data.map(form => form.id).join(',')}`;
        }
        if (!user) {
            filter += '&filters[require_login]=false';
        }
        apiService
            .get(
                `/forms?sort=createdAt:desc&populate=name&pagination[page]=${
                    tablePage + 1
                }&pagination[pageSize]=${rowsPerPage}${filter}`
            )
            .then(resp => {
                setForms(resp.data);
                setTotalCount(resp.meta.pagination.total);
            });
    }, [user, pageContent, tablePage, rowsPerPage]);

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 95px)'
            }}
        >
            <section className="w-full py-12">
                <div className="container px-4 md:px-6 mx-auto">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('FORMS_NAME')}</TableCell>
                                    <TableCell>{t('FORMS_DATE')}</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {forms?.map(form => (
                                    <TableRow
                                        key={`form-${form.id}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{form.attributes.name}</TableCell>
                                        <TableCell>
                                            {moment(form.attributes.createdAt).format(t('SHORT_DATE_FORMAT'))}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                onClick={() => {
                                                    navigate(`/form/${form.attributes.uid}`);
                                                }}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                {t('FORMS_GO_TO')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {forms.length !== rowsPerPage && (
                                    <TableRow style={{ height: 69 * (rowsPerPage - forms.length) }}>
                                        <TableCell colSpan={4} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={4}
                                        count={totalCount}
                                        rowsPerPage={rowsPerPage}
                                        page={tablePage}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page'
                                            },
                                            native: true
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </section>
        </div>
    );
}
