import {
    Button,
    DialogContentText,
    Icon,
    IconButton,
    TableFooter,
    TablePagination,
    Tooltip,
    Typography
} from '@mui/material';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../services/apiService';
import ConfirmationDialog from '../../ui-components/ConfirmationDialog';
import { showMessage } from '../../store/shared/messageSlice';
import Loading from '../../ui-components/Loading';
import { selectUser } from '../../store/shared/authSlice';

export default function MyPostsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const [posts, setPosts] = useState(null);
    const { t } = useTranslation();
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (posts && searchParams.has('delete')) {
            const deleteId = searchParams.get('delete');
            setConfirmDelete(posts.find(post => `${post.id}` === `${deleteId}`));
        }
    }, [posts, searchParams]);

    const handleChangePage = (event, newPage) => {
        setTablePage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const requestPosts = useCallback(() => {
        apiService
            .get(
                `/my-posts?publicationState=preview&sort=createdAt:desc&populate[0]=title&populate[1]=location&populate[2]=description&populate[3]=createdAt&populate[4]=updatedAt&populate[5]=educations&populate[6]=educations.name&pagination[pageSize]=${rowsPerPage}&pagination[page]=${tablePage}`
            )
            .then(response => {
                setPosts(response.data);
                setTotalCount(response.meta.pagination.pageCount);
            });
    }, [rowsPerPage, tablePage]);

    useEffect(() => {
        requestPosts();
    }, [requestPosts]);

    async function handleDeletePost(result) {
        if (result) {
            setDeleteLoading(true);
            await apiService
                .delete(`/posts/${confirmDelete.id}`)
                .then(() => {
                    dispatch(showMessage({ message: t('MY_POSTS_DELETED'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                });
            requestPosts();
            setDeleteLoading(false);
        }
        setSearchParams({});
        setConfirmDelete(null);
    }

    async function handleToggleVisibility(post) {
        if (post.attributes.publishedAt) {
            await apiService
                .put(`/posts/${post.id}`, {
                    data: {
                        publishedAt: null
                    }
                })
                .then(() => {
                    dispatch(showMessage({ message: t('MY_POSTS_VISIBILITY_HIDDEN'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                });
        } else {
            await apiService
                .put(`/posts/${post.id}`, {
                    data: {
                        publishedAt: new Date().toISOString()
                    }
                })
                .then(() => {
                    dispatch(showMessage({ message: t('MY_POSTS_VISIBILITY_SHOW'), variant: 'success' }));
                })
                .catch(e => {
                    dispatch(showMessage({ message: e.response.data.error.message, variant: 'error' }));
                });
        }
        requestPosts();
    }

    return (
        <div
            className="bg-[#E5E7EB]"
            style={{
                minHeight: 'calc(100vh - 95px)'
            }}
        >
            <div
                className="container space-y-12 px-4 md:px-6 mx-auto"
                style={{
                    paddingBottom: '10rem'
                }}
            >
                <div className="flex justify-between items-center pt-6">
                    <Typography className="text-3xl font-bold tracking-tighter">{t('MY_POSTS')}</Typography>
                    <Tooltip title={!user?.isPartnerPaid ? t('MY_POSTS_MISSING_PARTNER') : ''}>
                        <span>
                            <Button
                                startIcon={<Icon>add</Icon>}
                                variant="contained"
                                onClick={() => navigate('/mine-opslag/nyt')}
                                disabled={!user?.isPartnerPaid}
                            >
                                {t('MY_POSTS_CREATE')}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('MY_POSTS_TITLE')}</TableCell>
                                <TableCell>{t('MY_POSTS_TYPE')}</TableCell>
                                <TableCell>{t('MY_POSTS_EDUCATIONS')}</TableCell>
                                <TableCell>{t('MY_POSTS_CREATED')}</TableCell>
                                <TableCell>{t('MY_POSTS_UPDATED')}</TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {posts?.map(post => (
                                <TableRow
                                    key={`form-${post.id}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{post.attributes.title}</TableCell>
                                    <TableCell>
                                        <Typography
                                            className={clsx(
                                                'text-xs font-medium px-2.5 py-0.5 rounded text-nowrap bg-primary text-white'
                                            )}
                                        >
                                            {t(`POST_TYPE_${post.attributes.type.toUpperCase()}`)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {post.attributes.educations?.data.length === 0 && t('MY_POSTS_ALL_EDUCATIONS')}
                                        {post.attributes.educations?.data
                                            .map(education => education.attributes.name)
                                            .join(', ')}
                                    </TableCell>
                                    <TableCell>
                                        {moment(post.attributes.createdAt).format(t('DATETIME_FORMAT'))}
                                    </TableCell>
                                    <TableCell>
                                        {moment(post.attributes.updatedAt).format(t('DATETIME_FORMAT'))}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title={t('MY_POSTS_PREVIEW')}>
                                            <IconButton
                                                onClick={() => navigate(`/opslag/${post.id}`)}
                                                color="primary"
                                                disabled={!post.attributes.publishedAt}
                                            >
                                                <Icon>preview</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t('POST_EDIT')}>
                                            <IconButton
                                                onClick={() => navigate(`/mine-opslag/${post.id}`)}
                                                color="primary"
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {!!post.attributes.publishedAt && (
                                            <Tooltip title={t('MY_POSTS_HIDE')}>
                                                <IconButton
                                                    onClick={() => handleToggleVisibility(post)}
                                                    color="primary"
                                                >
                                                    <Icon>visibility_off</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {!post.attributes.publishedAt && (
                                            <Tooltip
                                                title={
                                                    user?.isPartnerPaid
                                                        ? t('MY_POSTS_SHOW')
                                                        : t('MY_POSTS_MISSING_PARTNER')
                                                }
                                            >
                                                <span>
                                                    <IconButton
                                                        onClick={() => handleToggleVisibility(post)}
                                                        color="primary"
                                                        disabled={!user?.isPartnerPaid}
                                                    >
                                                        <Icon>visibility</Icon>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={t('POST_DELETE')}>
                                            <IconButton
                                                onClick={() => {
                                                    setConfirmDelete(post);
                                                }}
                                                color="error"
                                            >
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {posts !== null && posts.length !== rowsPerPage && (
                                <TableRow style={{ height: 69 * (rowsPerPage - posts.length) }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {posts === null && (
                                <TableRow style={{ height: 69 * rowsPerPage }}>
                                    <TableCell colSpan={6}>
                                        <Loading fullWidth />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={6}
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={tablePage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <ConfirmationDialog
                    open={!!confirmDelete}
                    onClose={handleDeletePost}
                    title={t('MY_POSTS_CONFIRM_DELETE')}
                    okButton={t('MY_POSTS_CONFIRM_DELETE_BUTTON')}
                    cancelButton={t('CANCEL')}
                    loading={deleteLoading}
                >
                    <DialogContentText>
                        {t('MY_POSTS_ARE_YOU_SURE')}{' '}
                        <span className="font-bold">{confirmDelete?.attributes?.title}</span>
                    </DialogContentText>
                </ConfirmationDialog>
            </div>
        </div>
    );
}
