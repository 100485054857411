import {
    Avatar,
    Badge,
    Box,
    Button,
    ButtonGroup,
    CardHeader,
    Icon,
    IconButton,
    Typography,
    useTheme
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SwipeableButton } from 'react-swipeable-button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import apiService from '../../services/apiService';
import { selectUser, validate } from '../../store/shared/authSlice';
import { showMessage } from '../../store/shared/messageSlice';
import Counter from '../../ui-components/Counter';

export default function Coupon({ selectedCoupon, onClose, onUpdate, canConnect }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [amount, setAmount] = useState(1);
    const { t } = useTranslation();

    function handleClose() {
        onClose?.();
        setAmount(1);
        setLoading(false);
        setError(null);
        setSuccess(null);
    }

    function handleAdd() {
        setAmount(oldState => {
            if (oldState >= selectedCoupon.amountLeft) {
                return selectedCoupon.amountLeft;
            }
            return oldState + 1;
        });
    }

    function handleRemove() {
        setAmount(oldState => {
            if (oldState <= 1) return 1;
            return oldState - 1;
        });
    }

    async function handleUse() {
        setLoading(true);
        try {
            if (!canConnect) {
                await apiService.post(`/coupon/user/${selectedCoupon.uid}`, {
                    amount
                });
            } else {
                await apiService.post(`/coupon/anonymous/${selectedCoupon.uid}`, {
                    amount
                });
            }
            setSuccess(true);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
        onUpdate();
    }

    async function handleSetConnection() {
        await apiService.put(`/coupon/anonymous/${selectedCoupon.uid}`, {});
        dispatch(validate());
        navigate(`/mine-klippekort`);
        dispatch(showMessage({ message: t('COUPON_ADDED_TO_ACCOUNT'), variant: 'success' }));
    }

    return (
        <>
            <CardHeader
                sx={{
                    bgcolor: selectedCoupon?.coupon_type?.colors?.backgroundColor || 'primary.main',
                    '& .MuiCardHeader-title': {
                        color: selectedCoupon?.coupon_type?.colors?.textColor || 'primary.contrastText'
                    }
                }}
                classes={{ title: 'font-bold text-2xl uppercase' }}
                avatar={
                    <Avatar
                        sx={{
                            bgcolor: selectedCoupon?.coupon_type?.colors?.textColor || 'primary.contrastText',
                            color: selectedCoupon?.coupon_type?.colors?.backgroundColor || 'primary.main',
                            fontWeight: 'bold'
                        }}
                    >
                        {selectedCoupon.amountLeft}
                    </Avatar>
                }
                title={selectedCoupon.coupon_type.name}
                action={
                    <>
                        {!!onClose && (
                            <IconButton
                                onClick={() => handleClose(false)}
                                sx={{
                                    color: selectedCoupon?.coupon_type?.colors?.textColor || 'primary.contrastText'
                                }}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        )}
                        {canConnect && user && (
                            <Button variant="contained" onClick={handleSetConnection}>
                                {t('LINK_YOUR_ACCOUNT')}
                            </Button>
                        )}
                    </>
                }
            />
            <DialogContent
                dividers
                // className={clsx(loading && 'bg-yellow-300')}
                sx={[
                    loading && {
                        bgcolor: 'warning.light'
                    },
                    error && {
                        bgcolor: 'error.light'
                    },
                    success && {
                        bgcolor: 'success.light'
                    }
                ]}
            >
                {success && (
                    <>
                        <Typography className="text-center">{selectedCoupon.coupon_type.name}</Typography>
                        <div className="flex justify-center my-2">
                            <Badge className="text-xs font-medium px-2.5 py-0.5 rounded bg-green-600 text-white">
                                <Icon className="text-xs mr-1" size="inherit">
                                    check
                                </Icon>
                                {t('COUPON_APPLIED')}
                            </Badge>
                        </div>
                        <Typography className="text-6xl font-bold text-center mb-2">{amount}</Typography>
                        <Typography className="text-xl text-center ">
                            <Counter date={moment()} />
                        </Typography>
                    </>
                )}
                {error && (
                    <>
                        <Typography className="text-xl text-center mb-2 text-white">
                            {t('COUPON_NOT_APPLIED')}
                        </Typography>
                        <Typography className="text-center text-white">{t('COUPON_ERROR_TRY_AGAIN_LATER')}</Typography>
                    </>
                )}
                {!success && !error && (
                    <>
                        <Box
                            className="border-b-0 p-6 border-solid rounded-t-sm"
                            sx={{
                                borderWidth: '1px',
                                borderColor: 'primary.light'
                            }}
                        >
                            <Typography className="text-4xl font-bold text-center">{amount}</Typography>
                        </Box>
                        <div>
                            <ButtonGroup fullWidth>
                                <Button
                                    onClick={() => handleRemove(false)}
                                    variant="outlined"
                                    sx={{
                                        borderColor: 'primary.light',
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        padding: '1.5rem 0'
                                    }}
                                >
                                    <RemoveIcon color="error" />
                                </Button>
                                <Button
                                    onClick={() => handleAdd(false)}
                                    variant="outlined"
                                    sx={{
                                        borderColor: 'primary.light',
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0,
                                        padding: '1.5rem 0'
                                    }}
                                >
                                    <AddIcon color="success" />
                                </Button>
                            </ButtonGroup>
                            <Typography className="text-center font-bold mt-8 mb-2 uppercase" color="error">
                                {t('COUPON_REDEEM_ONLY_BY_STAFF')}
                            </Typography>
                            <div className="w-full">
                                <SwipeableButton
                                    onSuccess={() => handleUse()}
                                    text={t('COUPON_REDEEM')}
                                    text_unlocked="INDLØSER..."
                                    color={theme.palette.success.main}
                                />
                            </div>
                            {/* <LoadingButton */}
                            {/*    loading={loading} */}
                            {/*    className="w-full mt-4" */}
                            {/*    onClick={() => handleUse()} */}
                            {/*    variant="contained" */}
                            {/*    color="success" */}
                            {/* > */}
                            {/*    <div className="flex flex-col justify-center"> */}
                            {/*        {t('COUPON_REDEEM')} */}
                            {/*        <br /> */}
                            {/*        <span className="text-xs opacity-75">{t('COUPON_REDEEM_ONLY_BY_STAFF')}</span> */}
                            {/*    </div> */}
                            {/* </LoadingButton> */}
                        </div>
                    </>
                )}
            </DialogContent>
        </>
    );
}
