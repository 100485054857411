import { useMemo, useState } from 'react';
import { Avatar, Badge, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import clsx from 'clsx';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectUser, validate } from '../../store/shared/authSlice';
import Coupon from './Coupon';
import { selectPageForBuyCoupons } from '../../store/shared/configSlice';

export default function CouponsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const pageForBuyCoupons = useSelector(selectPageForBuyCoupons);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    function handleOpenCoupon(coupon) {
        setOpen(true);
        setSelectedCoupon(coupon);
    }

    const coupons = useMemo(() => {
        const _coupons = _.cloneDeep(user?.coupons || []);
        _coupons.sort((a, b) => b.amountLeft - a.amountLeft);
        return _coupons.filter(coupon => moment(coupon.expiration).isAfter(moment()));
    }, [user]);

    return (
        <div className="h-screen bg-[#E5E7EB] py-10">
            <div className="container px-4 md:px-6 mx-auto pb-10">
                {coupons.length > 0 && (
                    <div className="flex flex-col items-center mb-10">
                        <Typography variant="h6" className="text-2xl">
                            {t('COUPONS_YOUR_COUPONS')}
                        </Typography>
                        <Typography>{t('COUPONS_YOUR_COUPONS_DESC')}</Typography>
                    </div>
                )}
                <Grid container spacing={3}>
                    {coupons.length === 0 && (
                        <Grid item xs={12}>
                            <div className="flex justify-center items-center flex-col p-20">
                                <SentimentDissatisfiedIcon fontSize="inherit" style={{ fontSize: '10rem' }} />
                                <Typography className="mt-4 text-3xl font-bold">{t('COUPONS_NO_COUPONS')}</Typography>
                                <Button
                                    onClick={() => navigate(pageForBuyCoupons?.data?.attributes?.uri || '/')}
                                    className="mt-6"
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('COUPONS_BUY_HERE')}
                                </Button>
                            </div>
                        </Grid>
                    )}
                    {coupons.map(coupon => (
                        <Grid item xs={12} sm={6} md={3} key={`coupon${coupon.id}`}>
                            <Card className={clsx('rounded-lg', coupon.amountLeft <= 0 && 'opacity-70')}>
                                <CardHeader
                                    sx={{
                                        bgcolor: coupon?.coupon_type?.colors?.backgroundColor || 'primary.main',
                                        '& .MuiCardHeader-title': {
                                            color: coupon?.coupon_type?.colors?.textColor || 'primary.contrastText'
                                        }
                                    }}
                                    classes={{ title: 'font-bold text-2xl uppercase' }}
                                    avatar={
                                        <Avatar
                                            sx={{
                                                bgcolor:
                                                    coupon?.coupon_type?.colors?.textColor || 'primary.contrastText',
                                                color: coupon?.coupon_type?.colors?.backgroundColor || 'primary.main',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {coupon.amountLeft}
                                        </Avatar>
                                    }
                                    title={coupon.coupon_type.name}
                                />
                                <CardContent className="-mt-2 -mb-6">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: coupon.coupon_type.description }}
                                        className="ck-content"
                                    />
                                    <Badge
                                        className={clsx(
                                            'text-xs font-medium px-2 py-0.5 rounded text-white',
                                            moment(coupon.expiration).diff(moment(), 'days') <= 30 &&
                                                moment(coupon.expiration).diff(moment(), 'days') > 7 &&
                                                'bg-yellow-600',
                                            moment(coupon.expiration).diff(moment(), 'days') <= 7 && 'bg-red-600',
                                            moment(coupon.expiration).diff(moment(), 'days') > 30 && 'bg-green-600'
                                        )}
                                    >
                                        {t('COUPONS_EXPIRES')} {moment(coupon.expiration).fromNow()}
                                    </Badge>
                                </CardContent>
                                <CardActions className="p-[16px]">
                                    <Button
                                        className="w-full"
                                        color="success"
                                        variant="outlined"
                                        onClick={() => handleOpenCoupon(coupon)}
                                        disabled={coupon.amountLeft <= 0}
                                    >
                                        {coupon.amountLeft <= 0 ? t('COUPONS_USED') : t('COUPONS_USE')}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                {!!selectedCoupon && (
                    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                        <Coupon
                            selectedCoupon={selectedCoupon}
                            onClose={() => setOpen(false)}
                            onUpdate={() => dispatch(validate())}
                        />
                    </Dialog>
                )}
            </div>
        </div>
    );
}
