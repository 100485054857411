import { useEffect, useMemo } from 'react';
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    Icon,
    Link as MuiLink,
    TextField,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import LayoutHeader from '../../layout/LayoutHeader';
import TextFieldForm from '../../ui-components/TextFieldForm';
import {
    selectAuthError,
    selectForgotPasswordSuccess,
    selectUser,
    sendResetPasswordLink,
    setAuthError
} from '../../store/shared/authSlice';

export default function ForgotPasswordPage() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const authError = useSelector(selectAuthError);
    const forgotPasswordSuccess = useSelector(selectForgotPasswordSuccess);
    const navigate = useNavigate();
    const { handleSubmit, control, watch, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: ''
        }
    });
    const form = watch();
    const { isSubmitting } = formState;
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setAuthError(null));
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            navigate('/min-profil');
        }
    }, [navigate, user]);

    function onSubmit(data) {
        dispatch(sendResetPasswordLink(data.email));
    }

    return (
        <div className="h-screen bg-[#E5E7EB]">
            <LayoutHeader />

            <div
                style={{
                    paddingTop: '95px'
                }}
            >
                <Card className="mx-auto my-10 sm:my-20 p-2 sm:p-8 w-full sm:w-96 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardContent className="space-y-4">
                            <Typography variant="h6" className="text-2xl">
                                {t('FORGOT_PASSWORD')}
                            </Typography>
                            <Typography>{t('FORGOT_PASSWORD_TEXT')}</Typography>
                            {authError && (
                                <Alert severity="error" className="-mx-6 sm:-mx-12 -mt-4">
                                    {authError.message}
                                </Alert>
                            )}
                            {forgotPasswordSuccess?.ok && (
                                <Alert severity="success" className="-mx-12 -mt-4">
                                    {t('RESET_LINK_EMAIL_SENT_TO')} {form.email}
                                    {t('CLICK_THE_LINK_TO_RESET')}
                                </Alert>
                            )}
                            <div className="">
                                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                                    {t('EMAIL')}
                                </label>
                                <TextFieldForm
                                    control={control}
                                    autoComplete="email"
                                    size="small"
                                    placeholder={t('YOUR_EMAIL')}
                                    className="w-full"
                                    id="email"
                                    name="email"
                                    required
                                    type="email"
                                    disabled={forgotPasswordSuccess?.ok}
                                />
                            </div>
                            <LoadingButton
                                className="w-full py-2 px-4"
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={forgotPasswordSuccess?.ok}
                                loading={isSubmitting}
                            >
                                {t('SEND_RESET_LINK')}
                            </LoadingButton>
                        </CardContent>
                    </form>
                    <CardActions>
                        <div className="flex justify-center flex-col items-center w-full text-gray-600">
                            <MuiLink className="text-blue-500 hover:underline" to="/login" component={Link}>
                                {t('LOGIN_INSTEAD')}
                            </MuiLink>
                        </div>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
}
