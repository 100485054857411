import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import apiService from '../services/apiService';
import 'swiper/css';

export default function NewsScreenPage() {
    const [elementId, setElementId] = useState(0);
    const [elements, setElements] = useState(null);
    const swiperRefLocal = useRef();
    const settingsQuery = new URLSearchParams(window.location.search);

    const selectedElement = useMemo(() => elements?.[elementId]?.attributes, [elements, elementId]);

    useEffect(() => {
        if (['video/mp4'].includes(selectedElement?.mime)) {
            return () => {};
        }
        const query = new URLSearchParams(window.location.search);
        const timer = setInterval(
            () => {
                setElementId(oldState => (oldState + 1) % (elements?.length || 0));
            },
            query.get('timer') || 5000
        );

        return () => {
            clearInterval(timer);
        };
    }, [elements?.length, selectedElement?.mime]);

    const updateElements = useCallback(() => {
        apiService
            .get('/global-setting?populate=news')
            .then(response => {
                setElements(response.data.attributes.news.data);
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        updateElements();
        const interval = setInterval(
            () => {
                updateElements();
            },
            1000 * 60 * 10
        );
        return () => clearInterval(interval);
    }, [updateElements]);

    useEffect(() => {
        swiperRefLocal?.current?.swiper?.slideTo(elementId);
    }, [elementId]);

    useEffect(() => {
        if (!['video/mp4'].includes(selectedElement?.mime)) {
            return () => {};
        }
        const func = () => {
            setElementId(oldState => (oldState + 1) % (elements?.length || 0));
        };
        document.querySelector('#videoRef')?.play();
        document.querySelector('#videoRef')?.addEventListener('ended', func);
        return () => {
            document.querySelector('#videoRef')?.removeEventListener('ended', func);
        };
    }, [elements?.length, selectedElement?.mime]);

    return (
        <div
            className="w-full h-screen"
            style={{
                backgroundColor: settingsQuery?.get('bg') ? `#${settingsQuery?.get('bg')}` : undefined
            }}
        >
            <Swiper spaceBetween={30} centeredSlides navigation className="mySwiper" ref={swiperRefLocal}>
                {elements?.map((element, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex items-center justify-center w-screen h-screen">
                            {['image/png', 'image/jpeg'].includes(element?.attributes?.mime) && (
                                <img
                                    className={clsx('max-h-screen max-w-full object-contain', {
                                        'w-full': element.attributes.width > element.attributes.height,
                                        'h-full': element.attributes.width < element.attributes.height
                                    })}
                                    src={apiService.getImage(element.attributes.url)}
                                    alt=""
                                />
                            )}
                            {['video/mp4'].includes(element?.attributes?.mime) && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                <video width="100%" height="100%" autoPlay muted id="videoRef">
                                    <source
                                        src={apiService.getImage(element.attributes.url)}
                                        type={element.attributes.mime}
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
